<template>
  <Headline v-if="showTitle" type="h1" classes="h3 text-center" :text="title" />
  <div>
    <Section :elements="this.content" background="mint" id="blog_1">
      <div v-if="!categoryID" class="button-group mb-10 category-filter">
        <Buttons v-for="category of categories" :key="category.id" type="filter" :href="'/blog/' + category.id" :icon="true" :content="category.attributes.name" :icon_type="category.attributes.icon" icon_weight="light" />
      </div>
    </Section>
    <Section id="blog_2" background="mint_light">
      <div class="entry-list">
        <Headline v-if="listTitle" type="h3" classes="subline3 mb-2.5" :text="listTitle" />
        <Teasers v-for="entry of entries" :key="entry.id" :title="entry.attributes.name" :text="entry.attributes.Teasertext" :href="'/discovery/' + entry.attributes.permalink" :image="entry.attributes.Titelbild.data ? entry.attributes.Titelbild.data.attributes : null" :category_title="entry.attributes.blog_kategorien.data.attributes.name" :category_icon="entry.attributes.blog_kategorien.data.attributes.icon" type="blog" />
      </div>
    </Section>
    <Section id="blog_3" background="mint" v-if="categoryID">
      <Headline type="h3" classes="subline3 mb-3" :text="$t('message.moreEntries')" />
      <div class="button-group">
        <Buttons v-for="category of categories" :key="category.id" type="filter" :href="'/blog/' + category.id" :icon="true" :content="category.attributes.name" :icon_type="category.attributes.icon" icon_weight="light" />
      </div>
    </Section>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import ApiService from "@/services/apiService";
import eventService from "@/services/eventService";
import qs from "qs";
import filterService from "@/services/filterService";
import Meta from '@/services/metaService'

const Teasers = defineAsyncComponent(() => import('@/components/Teasers'));
const Buttons = defineAsyncComponent(() => import('@/components/Buttons'));
const Section = defineAsyncComponent(() => import('@/layout/Section'));
const Headline = defineAsyncComponent(() => import('@/components/Headline'));

export default {
  name: "Blog",
  data() {
    return {
      content: null,
      categories: null,
      title: null,
      listTitle: null,
      entries: [],
      categoryID: null,
      category: null,
      description: '',
      ogImage: null,
      showTitle: true
    }
  },
  components: {
    Teasers,
    Buttons,
    Section,
    Headline
  },
  mounted() {
    eventService.$on('lang-switch', () => {
      this.fetchEntries();
      this.fetchCategories();
    });
  },
  async created() {
    this.categoryID = this.$route.params.slug;
    this.fetchEntries();
    this.fetchCategories();
    if(this.categoryID) {
      this.fetchCategory();
    } else {
      this.fetchContent();
    }
    this.rerender()
  },
  methods: {
    rewrite(name) {
      return filterService.rewriteUrl(name)
    },
    fetchMeta() {
      let queryOptions = this.getQueryOptions(this.$root.$i18n.locale);
      queryOptions.populate.ogImage = {populate: '*'}
      let query = qs.stringify(queryOptions);
      ApiService.get(`allgemein?${query}`).then(res => {
        let data = res.data.data;
        if(data) {
          //let favicon = ApiService.getBaseUrl() + data.attributes.Favicon.data.attributes.url
          Meta.createMeta({name: 'name', content: 'description'}, {name: 'content', content: this.description })
          Meta.createMeta({name: 'property', content: 'og:title'}, {name: 'content', content: this.title})
          Meta.createMeta({name: 'property', content: 'og:description'}, {name: 'content', content: this.description})
          Meta.createMeta({name: 'property', content: 'og:image'}, {name: 'content', content: this.ogImage.data ? ApiService.getBaseUrl() + this.ogImage.data.attributes.url : ApiService.getBaseUrl() + data.attributes.ogImage.data.attributes.url})
          Meta.createMeta({name: 'property', content: 'og:url'}, {name: 'content', content: window.location.href})
          Meta.createMeta({name: 'property', content: 'og:site_name'}, {name: 'content', content: data.attributes.Name})
          Meta.createMeta({name: 'name', content: 'robots'}, {name: 'content', content: 'index, follow'})
          Meta.createLink('canonical', window.location.href)
        }
        document.title = this.title + " | " + data.attributes.Name
      })
    },
    fetchContent() {
      this.$wait.start("page");
      let queryOptions = this.getQueryOptions(this.$root.$i18n.locale);
      queryOptions.populate.ogImage = {populate: '*'}
      let query = qs.stringify(queryOptions);
      ApiService.get(`blog-content?${query}`).then(res => {
        let data = res.data.data;
        if(data) {
          this.title = data.attributes.Headline;
          this.showTitle = true;
          this.listTitle = data.attributes.Listentitel;
          this.description = data.attributes.MetaDescription;
          this.ogImage = data.attributes.ogImage;
          this.content = [
            {
              __component: 'content.page-opener',
              Headline: data.attributes.SubHeadline,
              Text: data.attributes.Text,
              Image: {}
            }
          ];
          this.$wait.end("page");
        }
      })
    },
    fetchEntries() {
      this.$wait.start("page");
      let queryOptions = this.getQueryOptions(this.$root.$i18n.locale);
      queryOptions.sort = ["publishedAt:desc"];
      queryOptions.populate.Titelbild = {populate: '*'}
      queryOptions.populate.blog_kategorien = {populate: '*'}
      if(this.categoryID) {
        queryOptions.filters.blog_kategorien = {id: {$eq: this.categoryID}}
      }
      let query = qs.stringify(queryOptions);
      ApiService.get(`blogs?${query}`).then(res => {
        let data = res.data.data;
        if(data) {
          this.entries = data;
          this.$wait.end("page");
        }
      });
    },
    fetchCategories() {
      this.$wait.start("page");
      let queryOptions = this.getQueryOptions(this.$root.$i18n.locale);
      if(this.categoryID) {
        queryOptions.filters.id = {$ne: this.categoryID}
      }
      let query = qs.stringify(queryOptions);
      ApiService.get(`blog-kategoriens?${query}`).then(res => {
        let data = res.data.data;
        if(data) {
          this.categories = data;
          this.$wait.end("page");
        }
      })
    },
    fetchCategory() {
      this.$wait.start("page");
      let queryOptions = this.getQueryOptions(this.$root.$i18n.locale);
      queryOptions.populate.ogImage = {populate: '*'}
      queryOptions.populate.Titelbild = {populate: '*'}
      queryOptions.filters.id = {$eq: this.categoryID};
      console.log(queryOptions)
      let query = qs.stringify(queryOptions);
      ApiService.get(`blog-kategoriens?${query}`).then(res => {
        let data = res.data.data[0];
        if(data) {
          this.category = data;
          this.description = data.attributes.MetaDescription;
          this.ogImage = data.attributes.ogImage;
          this.title = data.attributes.name;
          this.showTitle = false;
          this.content = [
            {
              __component: 'content.page-opener',
              Headline: data.attributes.name,
              Text: data.attributes.Text,
              icon: data.attributes.icon,
              Image: {}
            }
          ];
          this.$wait.end("page");
          console.log(this.category)
        }
      })
    },
    getQueryOptions(locale) {
      return {
        populate: {},
        sort: [],
        filters: {},
        locale: [locale],
        fields: []
      }
    },
    rerender(){
      this.$nextTick(() => {
        this.fetchMeta()
      })
    }
  },
  unmounted() {
    Meta.destroyMeta({name: 'name', content: 'description'})
    Meta.destroyMeta({name: 'name', content: 'robots'})
    Meta.destroyMeta({name: 'property', content: 'og:title'})
    Meta.destroyMeta({name: 'property', content: 'og:description'})
    Meta.destroyMeta({name: 'property', content: 'og:image'})
    Meta.destroyMeta({name: 'property', content: 'og:url'})
    Meta.destroyMeta({name: 'property', content: 'og:site_name'})
    Meta.destroyLink('canonical')
  }
}
</script>

<style lang="scss">
  #section_blog_1 .page-opener.has-image {

    margin-bottom: -8rem;
  }
</style>
